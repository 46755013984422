<template>
  <BaseList
    :list-query="list"
    route="herGreatBritainDetail"
    locale-section="pages.herGreatBritain"
  />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "HerGreatBritainList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      list: gql`
        query list {
          list: hersGreatBritain {
            id
            name: uri
            uri
          }
        }
      `
    };
  }
};
</script>
